<header class="header">
  <h3>Logo</h3>
  <div *ngIf="auth.isAuthenticated$ | async; else loggedOut">
    <button mat-flat-button (click)="auth.logout()">Log out</button>
  </div>

  <ng-template #loggedOut>
    <button mat-flat-button (click)="auth.loginWithRedirect()">Log in</button>
  </ng-template>
</header>
